import React, { Component } from 'react';
import TejindoRedesApp from './TejindoRedesApp';
import LoginContainer from './Login';
import CategoriaDashboard from './CategoriasDashboard';
import BlogContainer from './BlogContainer';
import BlogDashboard from './BlogContainerEspecifico';
import SISSADigital from '../Components/Dashboard/Pages/Sissa-Digital-3';
import SISSAMonitoring from '../Components/Dashboard/Pages/Sissa-Monitoring-I';
import SISSAInfraestructura from '../Components/Dashboard/Pages/Sissa-Infraestructura-I';
// import Partners from '../Components/Dashboard/Pages/Partners'
import Contacto from '../Components/Dashboard/Contacto';
import PageContainer from '../Components/Dashboard/Pages/Inicio';
import QuienesSomos from '../Components/Dashboard/Pages/QienesSomos';
import NoEncontrado404 from '../Components/Dashboard/Pages/404';
import AvisoPrivacidad from '../Components/Dashboard/Pages/AvisoPrivacidad';
import Peaje from '../Components/Productos/Peaje';
import Ixmaki from '../Components/Productos/Ixmaki';
import VSS from '../Components/Productos/VSS';
import SISSAMonitoringLandingPage from '../Containers/SISSAMonitoringLandingPage';

import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom';

import { connect } from 'react-redux';
import TagManager from 'react-gtm-module';

class App extends Component {
    componentDidMount() {
        let tituloPath = window.location.pathname;
        tituloPath = tituloPath.split('/')[2];
        if (tituloPath) {
            if (window.location.pathname.split('/')[1] === 'Categorias') {
                this.props.onRequestCategoria(tituloPath);
            } else if (window.location.pathname.split('/')[1] === 'Nota') {
                this.props.onRequestNota(tituloPath);
            }
        }
    }

    render() {
        const tagManagerArgs = {
            gtmId: 'GTM-N4P9ZL5',
        };

        TagManager.initialize(tagManagerArgs);

        const { categoria, listaCategoriaNotas, cleanTitle, notaInfo } = this.props;

        const isAuthenticatedSession = sessionStorage.getItem('isAuthenticated');
        function Redit() {
            let tituloPath = window.location.pathname;
            let tituloPathNoError = window.location.pathname.split('/')[1];
            tituloPath = tituloPath.split('/')[2];
            if (tituloPathNoError === 'Categorias' || tituloPathNoError === 'Nota') {
                if (window.location.pathname.split('/')[1] === 'Categorias') {
                    if (listaCategoriaNotas !== undefined) {
                        return <Route exact path={'/Categorias/' + tituloPath} component={CategoriaDashboard} />;
                    } else {
                        return <Redirect from='*' to='/' />;
                    }
                } else if (window.location.pathname.split('/')[1] === 'Nota') {
                    if (notaInfo !== undefined) {
                        return <Route exact path={'/Nota/' + tituloPath + '/' + cleanTitle} component={BlogDashboard} />;
                    } else {
                        return <Redirect from='*' to='/' />;
                    }
                } else {
                    return <Redirect from='*' to='/' />;
                }
            } else {
                return <Redirect from='*' to='/' />;
            }
        }
        return (
            <div>
                <link rel='stylesheet' href='https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css' integrity='sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS' crossOrigin='anonymous' />

                <Router>
                    <Switch>
                        <Route exact path='/' component={PageContainer} />
                        <Route exact path='/infodigital' component={SISSAMonitoringLandingPage} />
                        <Route exact path='/login' component={LoginContainer} />
                        <Route exact path='/blogPrincipal' component={BlogContainer} />
                        <Route exact path={'/' + categoria} component={CategoriaDashboard} />
                        <Route exact path={'/' + cleanTitle} component={BlogDashboard} />
                        <Route exact path='/QuienesSomos' component={QuienesSomos} />
                        <Route exact path='/SISSADigital' component={SISSADigital} />
                        <Route exact path='/SISSAMonitoring' component={SISSAMonitoring} />
                        <Route exact path='/SISSAInfraestructura' component={SISSAInfraestructura} />
                        {/* <Route exact path='/Partners' component={Partners} /> */}
                        <Route exact path='/Contacto' component={Contacto} />
                        <Route exact path='/AvisoPrivacidad' component={AvisoPrivacidad} />
                        <Route exact path='/NoEncontrado' component={NoEncontrado404} />
                        <Route exact path='/peaje' component={Peaje} />
                        <Route exact path='/Ixmaki' component={Ixmaki} />
                        <Route exact path='/VSS' component={VSS} />
                        <PrivateRoute isAuthenticated={isAuthenticatedSession} path='/dashboard' component={TejindoRedesApp} />
                        <Redirect from='/Nota/378/sissa-monitoring-integral-implementa-los-ultimos-avances-tecnologicos-de-seguridad-en-refaccionaria-california' to='/' />
                        {/* <Redirect from='*' to='/' /> */}
                        <Redit />
                    </Switch>
                </Router>

                <a href='https://wa.me/525544660515' target='_blank' rel='noopener noreferrer' className='float'>
                    <i className='fas fa-phone my-float'></i>
                </a>
            </div>
        );
    }
}

const PrivateRoute = ({ component: Component, isAuthenticated, ...rest }) => <Route {...rest} render={(props) => (isAuthenticated ? <Component {...props} /> : <Redirect to='/home' />)} />;

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.LoginReducer.isAuthenticated,
        listaCategoriaNotas: state.AdministradorReducer.listaCategoriaNotas,
        categoria: state.AdministradorReducer.categoria,
        cleanTitle: state.AdministradorReducer.cleanTitle,
        notaInfo: state.AdministradorReducer.notaInfo,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onRequestCategoria: (categoria) => {
            dispatch({ type: 'GET_NOTAS_CATEGORIA_REQUEST', categoria: categoria, page: 0 });
        },
        onRequestNota: (tituloPath) => {
            dispatch({ type: 'GET_NOTA_ESPECIFICA_REQUEST', idNota: tituloPath, pagePalabraClave: 0, pageCategoria: 0 });
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
